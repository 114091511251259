<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">配信用顧客グループ管理</h1>
        <banner-hint>
          顧客グループはLINE配信機能にて営業メッセージやクーポンを配信する際に配信先ターゲットを限定するために使います。<br />
          「常連様向け・未利用者向け・週末来店顧客向け」と言ったサービス利用状況に応じてお客様をグループ分けして的確なターゲットにより刺さる内容を配信しましょう。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-if="groups.length"
      >
        <v-card
          class="px-10 py-7"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12" lg="6"
              class="mb-5 pt-1"
              v-for="(group, index) in groups"
              :key="group.customer_group_id"
            >

              <!-- 入力一列 -->
              <v-form :ref="'form-group-' + group.customer_group_id">
                <v-row no-gutters>
                  <v-col cols="9" sm="7" md="7"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="group.group_name"
                      required
                      label="顧客グループ名"
                      counter="30"
                      :rules="[valiRules.required, valiRules.max30]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" sm="2" md="2"
                    class="px-4"
                  >
                    <v-text-field
                      :value="group.customer_count"
                      readonly
                      label="顧客数"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3" md="3"
                    class="d-flex justify-start align-center pt-0 pl-2"
                    :class="$vuetify.breakpoint.lgAndUp ? 'justify-start' : 'justify-end'"
                  >
                    <v-btn
                      v-if="group.create"
                      depressed small
                      color="accent"
                      @click="createRow(group)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(group)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pt-0">
              <icon-info icon="lightbulb-on-outline" :square="true">
                ブラック認定されている顧客は指定配信の対象にはならないので顧客数には含まれません。
              </icon-info>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 要素追加ボタン -->
      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
          depressed
          color="primary"
          @click="groupdBlankRow()"
        >顧客グループを追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!groups.length" flat>
          <v-card-text>顧客グループが登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import BannerHint from "@/components/_BannerHint.vue";
import IconInfo from '@/components/_IconInfo.vue'

export default {
  components: {
    'loader': Loader,
    'overlay-message': OverlayMessage,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxId: 0,
      groups: [],
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.getData()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      this.setGroups(),

      await Promise.all([
        this.setCustomerCount(),
      ])

      this.loading = false
    },

    //入力列追加
    groupdBlankRow() {
      if (this.groups.length >= 12) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.groups.push({
        customer_group_id: ++this.maxId,
        group_name: '',
        customer_count: 0,
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    setGroups() {
      return this.adminApi.getReqWithAuth('customer-group/').then( results => {
        if (!results || !results.length) return

        this.maxId = Math.max(...results.map( row => parseInt(row.customer_group_id) ))
        this.groups = results
      })
    },

    //**************************************************
    // グループ顧客数取得
    //**************************************************
    setCustomerCount() {
      return this.adminApi.getReqWithAuth('customer-group/customer-count/').then( results => {
        if (!results || !results.length) return

        results.map( count => {
          const target = this.groups.find( group => group.customer_group_id === count.group_id )
          if (target !== undefined) target.customer_count = count.customer_count
        })
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(group) {
      if (!this.$refs['form-group-' + group.customer_group_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'create/customer-group/'
      const updateData = {group_name: group.group_name}
      const paylogroup = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('POST', apiPath, paylogroup).then( response => {
        group.customer_group_id = response.customer_group_id
        group.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(group) {
      if (!this.$refs['form-group-' + group.customer_group_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const apiPath = 'update/customer-group/' + group.customer_group_id

      const updateData = {group_name: group.group_name}
      const paylogroup = JSON.stringify(updateData)

      this.adminApi.apiReqWithData('PUT', apiPath, paylogroup).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.groups[index].create) {
        this.groups.splice(index, 1)
        return
      }

      const apiPath = 'delete/customer-group/' + this.groups[index].customer_group_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.groups.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
